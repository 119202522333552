/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: "Heebo", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: "miller-text", "Noto Sans TC", "Noto Sans KR", "Noto Sans JP", serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p, li {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 15px;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.css.map */
a, a:hover, a:active, a:visited, a:focus {
  text-decoration: none;
}
body {
  font-family: "Heebo", sans-serif;
  background: white;
}
h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 1em;
}
h2:after {
  margin-top: 26px;
  display: block;
  content: "";
  width: 80px;
  height: 2px;
  background: #41B4B4;
}
img {
  max-width: 100%;
}
.container {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}
@media (min-width: 768px) {
  .container {
    padding: 0 20px;
  }
}
.container:before, .container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.container:after {
  clear: both;
}
hr {
  border: none;
  background-color: #41B4B4;
  height: 1px;
}
ul.arrows {
  margin: 0 0 0 15px;
  padding: 0;
  list-style: none;
}
ul.arrows li {
  position: relative;
}
ul.arrows li:last-child {
  margin-bottom: 0;
}
ul.arrows li::before {
  position: absolute;
  content: ">";
  color: #41B4B4;
  font-weight: 600;
  left: -15px;
}
#main-header {
  height: 100vh;
  width: 100%;
  background: url("main-header.260178df.jpg") center bottom;
  background-size: cover;
  border-bottom: 10px solid #41B4B4;
}
#main-header .container {
  height: 100vh;
}
@media (max-width: 767px) {
  #main-header {
    background-position: right bottom;
  }
}
#main-header h1 {
  position: absolute;
  left: 15px;
  font-size: 38px;
  max-width: 260px;
  top: 24%;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.62px;
}
@media (min-width: 768px) {
  #main-header h1 {
    top: 29%;
    left: 20px;
    font-size: 62px;
    max-width: 675px;
    line-height: 66px;
  }
}
#main-header h1:after {
  margin-top: 26px;
  display: block;
  content: "";
  width: 80px;
  height: 2px;
  background: #41B4B4;
}
#main-header img {
  width: 140px;
}
@media (min-width: 768px) {
  #main-header img {
    width: 190px;
  }
}
#main-header ul {
  display: none;
  float: right;
  margin: 31px 0 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  #main-header ul {
    display: flex;
  }
}
#main-header li {
  flex: 0 1 auto;
  padding-left: 58px;
}
#main-header a {
  color: #041121;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  display: inline-block;
}
#main-header a:after {
  content: "";
  display: none;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  background: #41B4B4;
  height: 2px;
  width: 100%;
}
#main-header a:hover:after {
  display: block;
}
#about {
  padding: 41px 0;
}
@media (min-width: 768px) {
  #about {
    padding: 81px 0;
  }
}
#about .container:before, #about .container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#about .container:after {
  clear: both;
}
#about .left, #about .right {
  float: left;
}
@media (min-width: 768px) {
  #about .left {
    width: 48.6%;
  }
}
#about h2 {
  margin-bottom: 0;
}
#about .right {
  padding-top: 20px;
}
@media (min-width: 768px) {
  #about .right {
    width: 51.4%;
    padding-left: 95px;
    padding-top: 0;
  }
}
#sectors {
  background: #E8F6F6;
  border-bottom: 10px solid #41B4B4;
  padding: 41px 0;
}
@media (min-width: 768px) {
  #sectors {
    padding: 81px 0;
  }
}
@media (max-width: 767px) {
  #sectors {
    padding-top: 15px;
  }
}
#sectors .container:before, #sectors .container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#sectors .container:after {
  clear: both;
}
#sectors .left, #sectors .right {
  float: left;
}
#sectors .left {
  padding-bottom: 13px;
}
@media (min-width: 768px) {
  #sectors .left {
    width: 48.6%;
    padding-bottom: 0;
  }
}
#sectors .right {
  padding-top: 20px;
}
@media (min-width: 768px) {
  #sectors .right {
    width: 51.4%;
    padding-left: 95px;
    padding-top: 32px;
  }
}
#sectors p:last-of-type {
  padding-bottom: 8px;
}
#services {
  padding: 41px 0;
}
@media (min-width: 768px) {
  #services {
    padding: 81px 0;
  }
}
@media (max-width: 767px) {
  #services {
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) {
  #services .left, #services .right {
    float: left;
  }
}
#services .left {
  padding-bottom: 8px;
}
@media (min-width: 768px) {
  #services .left {
    width: 51.4%;
    padding-right: 95px;
    padding-top: 23px;
    padding-bottom: 0;
  }
}
#services .right {
  padding-top: 20px;
}
@media (min-width: 768px) {
  #services .right {
    width: 48.6%;
    padding-top: 0;
  }
}
#services p:last-of-type {
  padding-bottom: 8px;
}
#contact {
  background: #041121;
  border-bottom: 10px solid #41B4B4;
  padding: 41px 0;
}
@media (min-width: 768px) {
  #contact {
    padding: 81px 0;
  }
}
@media (max-width: 767px) {
  #contact {
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  #contact {
    padding-bottom: 12px;
  }
}
#contact .container:before, #contact .container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#contact .container:after {
  clear: both;
}
#contact p {
  color: white;
}
#contact a {
  color: white;
}
#contact a:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  #contact .col1, #contact .col2, #contact .col3 {
    float: left;
  }
}
@media (min-width: 768px) {
  #contact .col1 {
    width: 51.4%;
    padding-right: 95px;
  }
}
#contact .col1 img {
  margin-bottom: 38px;
  width: 100px;
}
@media (min-width: 768px) {
  #contact .col1 img {
    width: 137px;
  }
}
#contact .col1 h2 {
  color: #41B4B4;
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 768px) {
  #contact .col1 h2 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.4px;
  }
}
#contact .col1 h2:after {
  display: none;
}
#contact .col2 h2 {
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 600;
  color: #41B4B4;
}
#contact .col2 h2:after {
  display: none;
}
#contact .col2 .name {
  font-weight: 600;
}
@media (min-width: 768px) {
  #contact .col2 {
    width: 24.3%;
  }
}
#contact .col3 {
  padding-bottom: 41px;
}
@media (max-width: 767px) {
  #contact .col3 {
    padding-top: 30px;
  }
}
@media (min-width: 768px) {
  #contact .col3 {
    padding-bottom: 65px;
    padding-left: 30px;
    width: 24.3%;
  }
}
#contact .col3 img {
  width: 18px;
}
#contact .col3 ul {
  margin: 0 0 25px;
  padding: 0;
  list-style: none;
}
#contact .col3 ul:after {
  margin-top: 26px;
  display: block;
  content: "";
  width: 80px;
  height: 2px;
  background: #41B4B4;
}
#contact .col3 li {
  font-weight: 600;
  padding-bottom: 22px;
  margin: 0;
}
#contact .col3 li:last-child {
  padding-bottom: 6px;
}
#contact .col3 h2 {
  font-size: 15px;
  font-weight: 600;
  color: white;
}
#contact .col3 h2:after {
  display: none;
}
#contact .subfooter {
  padding-top: 8px;
}
@media (min-width: 768px) {
  #contact .subfooter {
    display: flex;
    width: 100%;
  }
}
#contact .subfooter p {
  font-size: 11px;
  color: white;
}
#contact .subfooter .left {
  flex: 0 1 100%;
}
#contact .subfooter .right {
  flex: 0 1 300px;
}
@media (min-width: 768px) {
  #contact .subfooter .right {
    text-align: right;
  }
}
/*# sourceMappingURL=index.a8a2ecfa.css.map */
