body {
    font-family: $font-main;
    background: white;
}

h2 {
    font-size: 28px;
    font-weight: $semi-bold;
    line-height: 32px;
    @include underline;
    margin-bottom: 1em;
}
img {
    max-width: 100%;
}
.container {
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 15px;
    @include for-desktop-up {
        padding: 0 20px;
    }
    position: relative;
    @include clearfix;
}
hr {
    border: none;
    background-color: #41B4B4;
    height: 1px;
}
ul.arrows {
    margin: 0 0 0 15px;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
    }

    li::before {
        position: absolute;
        content: ">";
        color: #41B4B4;
        font-weight: $semi-bold;
        left: -15px;
    }
}

#main-header {
    .container {
        height: 100vh;
    }

    height: 100vh;
    width: 100%;
    background: url('../img/main-header.jpg') center bottom;
    @include for-phone-only {
        background-position: right bottom;
    }
    background-size: cover;
    border-bottom: 10px solid #41B4B4;

    h1 {
        position: absolute;
        left: 15px;
        font-size: 38px;
        max-width: 260px;
        top: 24%;
        line-height: 42px;
        @include for-desktop-up {
            top: 29%;
            left: 20px;
            font-size: 62px;
            max-width: 675px;
            line-height: 66px;
        }
        font-weight: $semi-bold;
        letter-spacing: -0.62px;
        @include underline;
    }

    img {
        width: 140px;
        @include for-desktop-up {
            width: 190px;
        }
    }

    ul {
        display: none;
        @include for-desktop-up {
            display: flex;
        }
        float: right;
        margin: 31px 0 0;
        padding: 0;
        list-style: none;
    }
    li {
        flex: 0 1 auto;
        padding-left: 58px;
    }
    a {
        color: #041121;
        text-decoration: none;
        font-weight: $medium;
        font-size: 15px;
        position: relative;
        display: inline-block;

        &:after {
            content: "";
            display: none;
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            background: #41B4B4;
            height: 2px;
            width: 100%;
        }
        &:hover:after {
            display: block;
        }
    }
}

#about {
    @include sectionpad;

    .container {
        @include clearfix;
    }
    .left, .right {
        float: left;
    }
    .left {
        @include for-desktop-up {
            width: 48.6%;
        }
    }
    h2 {
        margin-bottom: 0;
    }
    .right {
        padding-top: 20px;
        @include for-desktop-up {
            width: 51.4%;
            padding-left: 95px;
            padding-top: 0;
        }
    }
}

#sectors {
    background: #E8F6F6;
    border-bottom: 10px solid #41B4B4;

    @include sectionpad;

    @include for-phone-only {
        padding-top: 15px;
    }

    .container {
        @include clearfix;
    }
    .left, .right {
        float: left;
    }
    .left {
        padding-bottom: 13px;
        @include for-desktop-up {
            width: 48.6%;
            padding-bottom: 0;
        }
    }
    .right {
        padding-top: 20px;
        @include for-desktop-up {
            width: 51.4%;
            padding-left: 95px;
            padding-top: 32px;
        }
    }
    p:last-of-type {
        padding-bottom: 8px;
    }
}

#services {
    @include sectionpad;

    @include for-phone-only {
        padding-bottom: 15px;
    }

    .left, .right {
        @include for-desktop-up {
            float: left;
        }
    }
    .left {
        padding-bottom: 8px;
        @include for-desktop-up {
            width: 51.4%;
            padding-right: 95px;
            padding-top: 23px;
            padding-bottom: 0;
        }
    }
    .right {
        padding-top: 20px;
        @include for-desktop-up {
            width: 48.6%;
            padding-top: 0;
        }
    }

    p:last-of-type {
        padding-bottom: 8px;
    }
}

#contact {
    background: #041121;
    border-bottom: 10px solid #41B4B4;
    @include sectionpad;
    @include for-phone-only {
        padding-bottom: 20px;
    }
    @include for-desktop-up {
        padding-bottom: 12px;
    }

    .container {
        @include clearfix;
    }
    p {
        color: white;
    }
    a {
        color: white;
        &:hover {
            text-decoration: underline;
        }
    }

    .col1, .col2, .col3 {
        @include for-desktop-up {
            float: left;
        }
    }
    .col1 {
        @include for-desktop-up {
            width: 51.4%;
            padding-right: 95px;
        }
        img {
            margin-bottom: 38px;
            width: 100px;

            @include for-desktop-up {
                width: 137px;
            }
        }
        h2 {
            color: #41B4B4;
            font-size: 28px;
            line-height: 32px;
            @include for-desktop-up {
                font-size: 40px;
                line-height: 44px;
                letter-spacing: -0.4px;
            }
            &:after {
                display: none;
            }
        }
    }
    .col2 {
        h2 {
            font-size: 15px;
            line-height: 1.5em;
            font-weight: $semi-bold;
            color: #41B4B4;
            &:after {
                display: none;
            }
        }
        .name {
            font-weight: $semi-bold;
        }

        @include for-desktop-up {
            width: 24.3%;
        }
    }
    .col3 {
        @include for-phone-only {
            padding-top: 30px;
        }

        padding-bottom: 41px;

        @include for-desktop-up {
            padding-bottom: 65px;
            padding-left: 30px;
            width: 24.3%;
        }
        img {
            width: 18px;
        }
        ul {
            margin: 0 0 25px;
            padding: 0;
            list-style: none;
            @include underline;
        }
        li {
            font-weight: $semi-bold;
            padding-bottom: 22px;
            margin: 0;
            &:last-child {
                padding-bottom: 6px;
            }
        }

        h2 {
            font-size: 15px;
            font-weight: $semi-bold;
            color: white;
            &:after {
                display: none;
            }
        }
    }

    .subfooter {
        padding-top: 8px;
        @include for-desktop-up {
            display: flex;
            width: 100%;
        }
        p {
            font-size: 11px;
            color: white;
        }

        .left {
            flex: 0 1 100%;
        }

        .right {
            flex: 0 1 300px;
            @include for-desktop-up {
                text-align: right;
            }
        }
    }
}
